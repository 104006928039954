<template>




	<b-card v-bind:title="title2" >



		<b-modal
          id="modal-2"
          title="Confirmation"
          ok-only
          ok-title="Yes"
          @ok="bulkWalletAdjustment"
          :hide-footer="alertMessage == 1"
      	>
          <b-card-text>
              <span v-if="alertMessage == 0">
                
                  Are you sure you want to process further?
              </span>
              
              <span v-else-if="alertMessage == 1">
                  <span v-if="returnMessageStatus == 0">
                      <b-alert
                      variant="success"
                      show
                      >
                      <div class="alert-body">
                          <span>{{returnMessage}}</span>
                      </div>
                      </b-alert>
                  </span>
                  <span v-else-if="returnMessageStatus == 1">
                      <b-alert
                      variant="danger"
                      show
                      >
                      <div class="alert-body">
                          <span>{{returnMessage}}</span>
                      </div>
                      </b-alert>
                  </span>
              </span>
          </b-card-text>
      	</b-modal>

		<b-modal
          id="modal-3"
          title="Confirmation"
          ok-only
          ok-title="Yes"
          @ok="bulkSettleFromCod"
          :hide-footer="alertMessage == 1"
      	>
          <b-card-text>
              <span v-if="alertMessage == 0">
                
                  Are you sure you want to process further?
              </span>
              
              <span v-else-if="alertMessage == 1">
                  <span v-if="returnMessageStatus == 0">
                      <b-alert
                      variant="success"
                      show
                      >
                      <div class="alert-body">
                          <span>{{returnMessage}}</span>
                      </div>
                      </b-alert>
                  </span>
                  <span v-else-if="returnMessageStatus == 1">
                      <b-alert
                      variant="danger"
                      show
                      >
                      <div class="alert-body">
                          <span>{{returnMessage}}</span>
                      </div>
                      </b-alert>
                  </span>
              </span>
          </b-card-text>
      	</b-modal>

		  <b-modal
          id="modal-6"
          title="Confirmation"
          ok-only
          ok-title="Yes"
          @ok="bulkSettleChargesFromCod"
          :hide-footer="alertMessage == 1"
      	>
          <b-card-text>
              <span v-if="alertMessage == 0">
                
                  Are you sure you want to process further?
              </span>
              
              <span v-else-if="alertMessage == 1">
                  <span v-if="returnMessageStatus == 0">
                      <b-alert
                      variant="success"
                      show
                      >
                      <div class="alert-body">
                          <span>{{returnMessage}}</span>
                      </div>
                      </b-alert>
                  </span>
                  <span v-else-if="returnMessageStatus == 1">
                      <b-alert
                      variant="danger"
                      show
                      >
                      <div class="alert-body">
                          <span>{{returnMessage}}</span>
                      </div>
                      </b-alert>
                  </span>
              </span>
          </b-card-text>
      	</b-modal>

		<b-modal
          id="modal-4"
          title="Confirmation"
          ok-only
          ok-title="Yes"
          @ok="bulkMarkRevenue"
          :hide-footer="alertMessage == 1"
      	>
          <b-card-text>
              <span v-if="alertMessage == 0">
                
                  Are you sure you want to process further?
              </span>
              
              <span v-else-if="alertMessage == 1">
                  <span v-if="returnMessageStatus == 0">
                      <b-alert
                      variant="success"
                      show
                      >
                      <div class="alert-body">
                          <span>{{returnMessage}}</span>
                      </div>
                      </b-alert>
                  </span>
                  <span v-else-if="returnMessageStatus == 1">
                      <b-alert
                      variant="danger"
                      show
                      >
                      <div class="alert-body">
                          <span>{{returnMessage}}</span>
                      </div>
                      </b-alert>
                  </span>
              </span>
          </b-card-text>
      	</b-modal>

		<b-modal
          id="modal-5"
          title="Confirmation"
          ok-only
          ok-title="Yes"
          @ok="bulkReconciled"
          :hide-footer="alertMessage == 1"
      	>
          <b-card-text>
              <span v-if="alertMessage == 0">
                
                  Are you sure you want to process further?
              </span>
              
              <span v-else-if="alertMessage == 1">
                  <span v-if="returnMessageStatus == 0">
                      <b-alert
                      variant="success"
                      show
                      >
                      <div class="alert-body">
                          <span>{{returnMessage}}</span>
                      </div>
                      </b-alert>
                  </span>
                  <span v-else-if="returnMessageStatus == 1">
                      <b-alert
                      variant="danger"
                      show
                      >
                      <div class="alert-body">
                          <span>{{returnMessage}}</span>
                      </div>
                      </b-alert>
                  </span>
              </span>
          </b-card-text>
      	</b-modal>
    
		

		<div class="demo-inline-spacing">
            <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" v-b-modal.modal-1 variant="primary"> Cancel Invoice</b-button>
        </div>
    
        <br>


		<b-modal id="modal-1" title="Confirmation"
          ok-only
          ok-title="Yes"
          @ok="okBtn"
          :hide-footer="alertMessage == 1"
		>
			<b-card-text>
              <span v-if="alertMessage == 0">
                
                  Are you sure you want to proceed further?
              </span>
			</b-card-text>
        </b-modal>
	
		<!-- table -->
		<vue-good-table
			ref="universal-payment-master-courier-invoices"
			max-height="600px"
			:columns="columns"
			:rows="rows"
			:row-style-class="rowStyleClassFn"
			:isLoading="isLoadingProp"
			:rtl="direction"
			:totalRows="totalRecords"
			:select-options="{
				enabled: true,
				selectOnCheckboxOnly: true,
				selectionInfoClass: 'custom-class',
				selectionText: 'rows selected',
				clearSelectionText: 'clear',
				disableSelectInfo: true,
				selectAllByGroup: true,
			}"
			@on-sort-change="onSortChange"
			:pagination-options="{
				enabled: false,
			}"
		>

			<div slot="loadingContent">
				<b-spinner ref="spinner_one" variant="primary" class="mr-1" type="grow" style="width: 4rem; height: 4rem" />
			</div>

			<div slot="table-actions">
				<!-- primary -->
				<b-dropdown
					id="dropdown-right"
					v-ripple.400="'rgba(255, 255, 255, 0.15)'"
					text="Action"
					variant="danger"
				>
					<b-dropdown-item v-on:click="adjustBtn" v-b-modal.modal-2>Adjust From Wallet</b-dropdown-item>
					<b-dropdown-item v-on:click="adjustBtn" v-b-modal.modal-3>Settle From COD</b-dropdown-item>
					<b-dropdown-item v-on:click="adjustBtn" v-b-modal.modal-6>Settle Shipping Charges From COD</b-dropdown-item>
					<b-dropdown-item v-on:click="adjustBtn" v-b-modal.modal-4>Mark Revenue</b-dropdown-item>
					<b-dropdown-item v-on:click="adjustBtn" v-b-modal.modal-5>Reconciled</b-dropdown-item>
				</b-dropdown>
     		</div>


			<template slot="table-row"  slot-scope="props">

				<span v-if="props.column.field === 'shipment_status'">
					<b-badge :variant="statusVariant(props.row.shipment_status)">
						{{ props.row.shipment_status }}
					</b-badge>
				</span>
				<span v-else>
					{{ props.formattedRow[props.column.field] }}
				</span>

				<!-- Column: Action -->
				<span v-if="props.column.field === 'action'">

					<b-dropdown v-if="props.row.payment_status == 2 || props.row.payment_status == 3" variant="link" no-caret toggle-class="p-0" right >

						<template #button-content>
							<b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="btn-icon">
								<feather-icon icon="SettingsIcon" />
							</b-button>
						</template>

						<b-dropdown-item v-if="props.row.seller_adjustment_amount <= -1 || props.row.seller_adjustment_amount >= 1"  v-on:click="walletAdjustment(props.row.shipment_id)" >
							<span class="align-middle">Adjust From Wallet</span>
						</b-dropdown-item>

						<b-dropdown-item v-if="props.row.seller_adjustment_amount <= -1"  v-on:click="settleFromCod(props.row.shipment_id)">
							<span class="align-middle">Settle Discrepancy From COD</span>
						</b-dropdown-item>

						<b-dropdown-item v-if="props.row.seller_adjustment_amount <= -1"  v-on:click="settleChargesFromCod(props.row.shipment_id)">
							<span class="align-middle">Settle Shipping Charges From COD</span>
						</b-dropdown-item>

						<b-dropdown-item v-if="props.row.seller_adjustment_amount >= 1" v-on:click="markRevenue(props.row.shipment_id)">
							<span class="align-middle">Mark Revenue</span>
						</b-dropdown-item>

						<b-dropdown-item v-if="props.row.seller_adjustment_amount >= -1 && props.row.seller_adjustment_amount <= 1 || props.row.payment_status == 3"  v-on:click="reconciled(props.row.shipment_id)">
							<span class="align-middle">Reconciled</span>
						</b-dropdown-item>

					</b-dropdown>
					
				</span>

			</template>

			<!-- pagination -->
			<template slot="pagination-bottom" slot-scope="props">
			
				<div class="d-flex justify-content-between flex-wrap">

					<!-- page length -->
					<div class="d-flex align-items-center mb-0 mt-1">
						<span class="text-nowrap"> Showing {{ props.total }} entries </span>
					</div>

				</div>
			</template>


		</vue-good-table>

			
	</b-card>


</template>


<script src="https://ajax.googleapis.com/ajax/libs/jquery/3.5.1/jquery.min.js"></script>
<script type="text/javascript" src="libs/FileSaver/FileSaver.min.js"></script>
<script type="text/javascript" src="libs/js-xlsx/xlsx.core.min.js"></script>
<script type="text/javascript" src="tableExport.min.js"></script>
<script src="https://cdnjs.cloudflare.com/ajax/libs/moment.js/2.18.1/moment.min.js"></script>

<script>
	import {
		BAvatar,
		BBadge,
		BPagination,
		BFormGroup,
		BFormInput,
		BFormSelect,
		BDropdownItem,
		BFormFile,
		BDropdown,
		BCard,
		BButton,
		BTabs,
		BTab,
		BFormDatepicker,
		BAlert,
		BSpinner,
		BCardText
	} from "bootstrap-vue";
	import { VueGoodTable } from "vue-good-table";
	import { ValidationProvider, ValidationObserver } from "vee-validate";
	import "prismjs";
	import "prismjs/themes/prism-tomorrow.css";
	import Prism from "vue-prism-component";
	import store from "@/store/index";
	import Ripple from "vue-ripple-directive";

	export default {

		components: {
			VueGoodTable,
			BCardText,
			BFormDatepicker,
			BAlert,
			BSpinner,
			ValidationProvider,
			ValidationObserver,
			BCard,
			BTab,
			BTabs,
			BAvatar,
			BBadge,
			BPagination,
			BFormGroup,
			BFormInput,
			BFormSelect,
			Prism,
			BDropdownItem,
			BDropdown,
			BButton,
			BFormFile
		},

		directives: {
			Ripple,
		},

		
		data() {

			return {
				
				title2 : "Courier Invoice Detail (" + this.$route.params.courier_name + " - " + this.$route.params.invoice_date + ")",
				allData: null,
				log: [],
				totalRecords: 0,
				alertMessage:0,
				get_main_data_url: "get-courier-paid-invoice-detail",
				dir: false,
				isLoadingProp: true,
				
				columns: [
					{
						label: "CN Number",
						field: "tracking_number",
					},
					{
						label: "Seller Name",
						field: "seller_name",
					},
					{
						label: "Seller Id",
						field: "seller_id",
					},
					{
						label: "Courier",
						field: "courier_name",
					},
					{
						label: "Courier Service",
						field: "courier_service",
					},
					{
						label: "Shipment Status",
						field: "shipment_status",
					},
					{
						label: "Pickup City",
						field: "pickup_city",
					},
					{
						label: "Destination City",
						field: "destination_city",
					},
					{
						label: "Invoice Date",
						field: "courier_payment_date",
					},
					{
						label: "COD Amount",
						field: "cod",
					},
					{
						label: "Weight",
						field: "unity_weight",
					},
					{
						label: "Actual Weight",
						field: "actual_weight",
					},
					{
						label: "Courier Charges Unity",
						field: "unity_weight_charges",
					},
					{
						label: "Actual Courier Charges",
						field: "actual_weight_charges",
					},
					{
						label: "Fuel Surcharged Charged",
						field: "unity_fuel_surcharge",
					},
					{
						label: "Actual Fuel Surcharged",
						field: "actual_fuel_surcharge",
					},
					{
						label: "Courier SST",
						field: "unity_sale_tax_for_courier",
					},
					{
						label: "Actual Courier Tax",
						field: "actual_sale_tax_for_courier",
					},
					{
						label: "Unity Total Charges",
						field: "unity_total_courier_charges_with_tax",
					},
					{
						label: "Actual Courier Total Charges",
						field: "actual_total_courier_charges_with_tax",
					},
					{
						label: "Unity Booking Charges",
						field: "unity_booking_charges",
					},
					{
						label: "SST On Unity",
						field: "unity_sst",
					},
					{
						label: "Total Payment From Seller",
						field: "payment_from_seller",
					},
					{
						label: "Discrepancy",
						field: "seller_adjustment_amount",
						type: 'number',
					},
					// {
					// 	label: "Discrepancy",
					// 	field: (row) => {
					// 		var discrepancy = row.seller_adjustment_amount;
					// 		return discrepancy == 0 ?  '-' : discrepancy ;
					// 	},
					// },
					{
						label: "Action",
						field: "action",
					},
				],
				rows: [],
				serverParams: {
					// a map of column filters example: {name: 'john', age: '20'}
					columnFilters: {},
					sort: {
						field: "seller_adjustment_amount", // example: 'name'
						type: "asc", // 'asc' or 'desc'
					},
					page: 1, // what page I want to show
					perPage: 20, // how many items I'm showing per page
				},
				searchTerm: "",
			};
		},



		computed: {

			statusVariant() {
				const statusColor = {
					/* eslint-disable key-spacing */
					Lost      : 'light-primary',
					Delivered : 'light-success',
					Return     : 'light-danger',
					Losts    : 'light-warning',
					Losst      : 'light-info',
					/* eslint-enable key-spacing */
				}

				return shipment_status => statusColor[shipment_status]
			},

			direction() {
				if (store.state.appConfig.isRTL) {
					this.dir = true;
					return this.dir;
				}
				this.dir = false;
				return this.dir;
			},

		},


		mounted() {
			this.loadItems();
		},



		methods: {

			/// TABLE METHODS START ///

			updateAllData(data) {
				this.allData = null;
				this.allData = data;
			},


			updateParams(newProps) {
				this.serverParams = Object.assign({}, this.serverParams, newProps);
			},


			handleChangePage(page) {
				this.updateParams({ page: page });
				// this.log.push(`The user changed the page to: ${page}`)
				this.loadItems();
			},


			handlePageChange(active) {
				this.updateParams({ perPage: active });
				this.log.push(`the user change page:  ${active}`);
				this.loadItems();
			},


			onSortChange(params) {
				this.updateParams({
					sort: params,
				});
				this.loadItems();
				this.log.push(`the user ordered:  ${params[0].type}`);
			},


			onColumnFilter(params) {
				this.updateParams(params);
				this.loadItems();
			},


			loadItems() {

				this.isLoadingProp = true;

				this.$http
				.post(this.get_main_data_url, {
					data: this.serverParams,
					courier_name: this.$route.params.courier_name,
					invoice_date: this.$route.params.invoice_date,
					invoice_id: this.$route.params.invoice_id,
				})
				.then((response) => {
					this.totalRecords = response.data.totalRecords;
					this.rows = response.data.data;
					this.updateAllData(response.data.data);
					this.isLoadingProp = false;
				})
				.catch((error) => {
					this.isLoadingProp = false;
					this.toast('danger', error.message, 'Table Data Failed');
				});
			},
			

			/// TABLE METHODS END ///





			formatNames: function(files) {
				if (files.length === 1) {
					return files[0].name
				}
				return `${files.length} files selected`
			},

			okBtn: function(){
				this.sendDataOnly();
			},

			read: function(ev) {
			    this.file = ev.target.files[0];
			},


			sendDataOnly: function() {
				
				var formData = new FormData();
				formData.append('courier_name', this.$route.params.courier_name);
				formData.append('invoice_date', this.$route.params.invoice_date);
				formData.append('invoice_id', this.$route.params.invoice_id);

				
				this.$http.post('cancel-invoice', formData, {
				
					headers: {
						'Content-Type': 'multipart/form-data'
					}
				
				}).then(res => {

					if (res.data.error == 1) {
						this.toast('danger', res.data.message, 'Failed');
					} else {
						this.toast('success', res.data.message, 'Success');
						this.$router.push('courier-invoices');
					}
				
				}).catch((error) => {
					this.isLoadingProp = false;
					this.toast('danger', error.message, 'Table Data Failed');
				});
				this.showTable = true;
				
			},


			rowStyleClassFn(row) {
				if(row.seller_adjustment_amount >= 1){
					return 'VGT-row-high';
				} else if(row.seller_adjustment_amount <= -1){
					return 'VGT-row-low';
				} 
			},


			toast(variant = null, message = 'Toast body content', title = variant) {
				
				message = this.$createElement('strong', { class: 'mr-2' }, message)

				this.$bvToast.toast(message, {
					title: title,
					variant,
					solid: false,
				})
			},








			calculateDiscrepancy(row) {
				
				if (	row.unity_sale_tax_for_courier &&
						row.actual_sale_tax_for_courier &&
						row.unity_fuel_surcharge && 
						row.actual_fuel_surcharge &&
						row.unity_weight_charges &&
						row.actual_weight_charges
					) {

					return (row.unity_sale_tax_for_courier-row.actual_sale_tax_for_courier) +
							(row.unity_fuel_surcharge-row.actual_fuel_surcharge) +
							(row.unity_weight_charges-row.actual_weight_charges);
				} else {
					return false;
				}
			},


			walletAdjustment(shipment_id) {
				
				this.isLoadingProp = true;

				this.$http
				.post('discrepancy-report/adjust-wallet', {
					data: shipment_id,
				})
				.then((res) => {
					
					if (res.data.error == 1) {
						this.toast('danger', res.data.message, 'Wallet Adjustment Request Failed');
					} else {
						this.toast('success', res.data.message, 'Wallet Adjustment Request Success');
						this.loadItems();
					}
					this.isLoadingProp = false;
				})
				.catch((error) => {
					this.isLoadingProp = false;
					this.toast('danger', error.message, 'Wallet Adjustment Request Failed');
				});
			},

			settleFromCod(shipment_id) {
				
				this.isLoadingProp = true;

				this.$http
				.post('discrepancy-report/adjust-cod', {
					data: shipment_id,
				})
				.then((res) => {
					
					if (res.data.error == 1) {
						this.toast('danger', res.data.message, 'Settle From COD Request Failed');
					} else {
						this.toast('success', res.data.message, 'Settle From COD Request Success');
						this.loadItems();
					}
					this.isLoadingProp = false;
				})
				.catch((error) => {
					this.isLoadingProp = false;
					this.toast('danger', error.message, 'Settle From COD Request Failed');
				});
			},

			settleChargesFromCod(shipment_id){
				this.isLoadingProp = true;

				this.$http
				.post('discrepancy-report/adjust-charges-from-cod', {
					data: shipment_id,
				})
				.then((res) => {
					
					if (res.data.error == 1) {
						this.toast('danger', res.data.message, 'Settle Charges From COD Request Failed');
					} else {
						this.toast('success', res.data.message, 'Settle Charges From COD Request Success');
						this.loadItems();
					}
					this.isLoadingProp = false;
				})
				.catch((error) => {
					this.isLoadingProp = false;
					this.toast('danger', error.message, 'Settle Charges From COD Request Failed');
				});
			},


			markRevenue(shipment_id) {
				
				this.isLoadingProp = true;

				this.$http
				.post('discrepancy/mark-revenue', {
					data: shipment_id
				})
				.then((res) => {
					
					if (res.data.error == 1) {
						this.toast('danger', res.data.message, 'Mark Revenue Request Failed');
					} else {
						this.toast('success', res.data.message, 'Mark Revenue Request Success');
						this.loadItems();
					}
					this.isLoadingProp = false;
				})
				.catch((error) => {
					this.isLoadingProp = false;
					this.toast('danger', error.message, 'Mark Revenue Request Failed');
				});
			},


			reconciled(shipment_id) {
				
				this.isLoadingProp = true;

				this.$http
				.post('discrepancy/reconciled', {
					data: shipment_id
				})
				.then((res) => {
					
					if (res.data.error == 1) {
						this.toast('danger', res.data.message, 'Reconciled Request Failed');
					} else {
						this.toast('success', res.data.message, 'Reconciled Request Success');
						this.loadItems();
					}
					this.isLoadingProp = false;
				})
				.catch((error) => {
					this.isLoadingProp = false;
					this.toast('danger', error.message, 'Reconciled Request Failed');
				});
			},


			adjustBtn(){
			
				this.alertMessage = 0;
				this.returnMessageStatus = 0;
				this.returnMessage = "";
			},


			// bulk action methods
				
			bulkWalletAdjustment(event) {

				this.alertMessage = 0;
				this.returnMessageStatus = 0;
				this.returnMessage = "";

				var error = 0;
				

				if((this.$refs['universal-payment-master-courier-invoices'].selectedRows).length > 0){

					(this.$refs['universal-payment-master-courier-invoices'].selectedRows).forEach(function(element,index){
						if(element.seller_adjustment_amount <= -1 || element.seller_adjustment_amount >= 1){

						} else{
							error = 1;
						}

					});

					if(error == 0){
					
						this.isLoadingProp = true;

						this.$http
						.post('discrepancy-report/adjust-wallet/bulk', this.$refs['universal-payment-master-courier-invoices'].selectedRows)
						.then((res) => {
							
							if (res.data.error == 1) {
								this.toast('danger', res.data.message, 'Wallet Adjustment Request Failed');
							} else {
								this.toast('success', res.data.message, 'Wallet Adjustment Request Success');
								this.loadItems();
							}
							this.isLoadingProp = false;
						})
						.catch((error) => {
							this.isLoadingProp = false;
							this.toast('danger', error.message, 'Wallet Adjustment Request Failed');
						});
					} else{
						event.preventDefault();
						this.alertMessage = 1;
						this.returnMessageStatus = 1;
						this.returnMessage = "Please select correct shipments for ths action";
					}
				} else{
					event.preventDefault();
					this.alertMessage = 1;
        			this.returnMessageStatus = 1;
        			this.returnMessage = "Please select a row";
				}
			},

			bulkSettleFromCod(event) {

				this.alertMessage = 0;
				this.returnMessageStatus = 0;
				this.returnMessage = "";

				var error = 0;

				if((this.$refs['universal-payment-master-courier-invoices'].selectedRows).length > 0){

					(this.$refs['universal-payment-master-courier-invoices'].selectedRows).forEach(function(element,index){
						if(element.seller_adjustment_amount <= -1){

						} else{
							error = 1;
						}

					});

					if(error == 0){
				
						this.isLoadingProp = true;

						this.$http
						.post('discrepancy-report/adjust-cod/bulk', this.$refs['universal-payment-master-courier-invoices'].selectedRows)
						.then((res) => {
							
							if (res.data.error == 1) {
								this.toast('danger', res.data.message, 'Settle From COD Request Failed');
							} else {
								this.toast('success', res.data.message, 'Settle From COD Request Success');
								this.loadItems();
							}
							this.isLoadingProp = false;
						})
						.catch((error) => {
							this.isLoadingProp = false;
							this.toast('danger', error.message, 'Settle From COD Request Failed');
						});
					} else{
						event.preventDefault();
						this.alertMessage = 1;
						this.returnMessageStatus = 1;
						this.returnMessage = "Please select correct shipments for ths action";
					}
				} else{
					event.preventDefault();
					this.alertMessage = 1;
        			this.returnMessageStatus = 1;
        			this.returnMessage = "Please select a row";
				}
			},

			bulkSettleChargesFromCod(event) {

				this.alertMessage = 0;
				this.returnMessageStatus = 0;
				this.returnMessage = "";

				var error = 0;

				if((this.$refs['universal-payment-master-courier-invoices'].selectedRows).length > 0){

					
				
					this.isLoadingProp = true;

					this.$http
					.post('discrepancy-report/adjust-charges-from-cod/bulk', this.$refs['universal-payment-master-courier-invoices'].selectedRows)
					.then((res) => {
						
						if (res.data.error == 1) {
							this.toast('danger', res.data.message, 'Settle Charges From COD Request Failed');
						} else {
							this.toast('success', res.data.message, 'Settle Charges From COD Request Success');
							this.loadItems();
						}
						this.isLoadingProp = false;
					})
					.catch((error) => {
						this.isLoadingProp = false;
						this.toast('danger', error.message, 'Settle Charges From COD Request Failed');
					});
				
				} else{
					event.preventDefault();
					this.alertMessage = 1;
        			this.returnMessageStatus = 1;
        			this.returnMessage = "Please select a row";
				}
			},


			bulkMarkRevenue(event) {

				this.alertMessage = 0;
				this.returnMessageStatus = 0;
				this.returnMessage = "";

				var error = 0;

				if((this.$refs['universal-payment-master-courier-invoices'].selectedRows).length > 0){
				
					(this.$refs['universal-payment-master-courier-invoices'].selectedRows).forEach(function(element,index){
						if(element.seller_adjustment_amount >= 1){

						} else{
							error = 1;
						}

					});

					if(error == 0){

						this.isLoadingProp = true;

						this.$http
						.post('discrepancy/mark-revenue/bulk', this.$refs['universal-payment-master-courier-invoices'].selectedRows)
						.then((res) => {
							
							if (res.data.error == 1) {
								this.toast('danger', res.data.message, 'Mark Revenue Request Failed');
							} else {
								this.toast('success', res.data.message, 'Mark Revenue Request Success');
								this.loadItems();
							}
							this.isLoadingProp = false;
						})
						.catch((error) => {
							this.isLoadingProp = false;
							this.toast('danger', error.message, 'Mark Revenue Request Failed');
						});
					} else{
						event.preventDefault();
						this.alertMessage = 1;
						this.returnMessageStatus = 1;
						this.returnMessage = "Please select correct shipments for ths action";
					}
				} else{
					event.preventDefault();
					this.alertMessage = 1;
        			this.returnMessageStatus = 1;
        			this.returnMessage = "Please select a row";
				}
			},


			bulkReconciled(event) {

				this.alertMessage = 0;
				this.returnMessageStatus = 0;
				this.returnMessage = "";

				var error = 0;

				if((this.$refs['universal-payment-master-courier-invoices'].selectedRows).length > 0){

					(this.$refs['universal-payment-master-courier-invoices'].selectedRows).forEach(function(element,index){
						if(element.seller_adjustment_amount >= -1 && element.seller_adjustment_amount <= 1 || element.payment_status == 3){

						} else{
							error = 1;
						}

					});

					if(error == 0){
				
						this.isLoadingProp = true;

						this.$http
						.post('discrepancy/reconciled/bulk', this.$refs['universal-payment-master-courier-invoices'].selectedRows)
						.then((res) => {
							
							if (res.data.error == 1) {
								this.toast('danger', res.data.message, 'Reconciled Request Failed');
							} else {
								this.toast('success', res.data.message, 'Reconciled Request Success');
								this.loadItems();
							}
							this.isLoadingProp = false;
						})
						.catch((error) => {
							this.isLoadingProp = false;
							this.toast('danger', error.message, 'Reconciled Request Failed');
						});
					} else{
						event.preventDefault();
						this.alertMessage = 1;
						this.returnMessageStatus = 1;
						this.returnMessage = "Please select correct shipments for ths action";
					}
				} else{
					event.preventDefault();
					this.alertMessage = 1;
        			this.returnMessageStatus = 1;
        			this.returnMessage = "Please select a row";
				}
			},



		},
	};


</script>

<style lang="scss">

	body.dark-layout .VGT-row td {
		background-color: #6e6c19 !important;
	}


	.VGT-row-low td {
		background-color: #F7A4A4 !important;
	}
	.VGT-row-high td {
		background-color: #B6E2A1 !important;
	}

	@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
